import type { FunctionComponent } from 'react'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

import { Theme } from '@sporza/config'
import clsx from 'clsx'

import Title, { TitleDirection, TitleElement, TitleSize } from '../../../atoms/title'
import Image, { ImageLayout } from '../../../molecules/image'
import styles from '../bio.module.scss'
import { BioProps } from '../index'

const imageWithHeight = (src: string, height: number) => {
  if (src.includes('?')) {
    return `${src}&h=${height}`
  } else {
    return `${src}?h=${height}`
  }
}

const BioImage: FunctionComponent<BioProps> = (
  {
    title,
    img,
    backgroundImg,
    subTitle,
    theme,
    designSystemBaseUrl
  }
) => {
  return <div
    style={{ '--bio-background': backgroundImg && `url(${imageWithHeight(backgroundImg, 250)}) center / cover` }}
    className={clsx(
      styles.bio,
      styles.bioImage,
      theme === Theme.Onverhard && styles.onverhard
    )}
  >
    <div className={styles.profile}>
      {/*<div style={{ position: 'relative', width: '150px', height: '138px' }}>*/}
        <Image
          src={img}
          alt={title}
          profiles={[{ name: 'native_mobile_200_200', view: '100w' }]}
          noCrop
          layout={ImageLayout.Square}
          priority={true}
          className={styles.image}
        />
        {/*<svg width="150" height="138" viewBox="0 0 150 138" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
        {/*  <path*/}
        {/*    d="M17.8405 112.192L17.8399 112.192C5.10181 105.473 -0.74548 90.3985 4.13125 76.8434L16.5316 42.3958C18.1357 37.9399 21.4116 31.6235 25.4433 26.0995C27.4558 23.3421 29.6313 20.8173 31.8497 18.831C34.0625 16.8499 36.241 15.474 38.276 14.8779L95.762 2.82754L95.8146 2.81653L95.8663 2.80175C101.259 1.26118 106.112 2.60685 110.633 5.5864C115.199 8.59601 119.328 13.2146 123.135 18.0256L141.849 41.6995C149.233 51.0418 150.139 63.9516 144.125 74.2288C144.125 74.2288 144.125 74.2289 144.125 74.2289L121.625 112.673C121.625 112.673 121.625 112.673 121.624 112.674C118.094 118.683 112.527 123.223 105.93 125.455C105.93 125.455 105.93 125.455 105.93 125.455L79.3172 134.458L79.3167 134.458C71.8122 136.999 63.5808 136.334 56.5613 132.64C56.561 132.64 56.5607 132.64 56.5604 132.64L17.8405 112.192Z"*/}
        {/*    stroke="url(#onverhard_linear_gradient)" strokeWidth="3"></path>*/}
        {/*  <defs>*/}
        {/*    <linearGradient id="onverhard_linear_gradient" x1="142" y1="124" x2="11.9431" y2="26.7708"*/}
        {/*                    gradientUnits="userSpaceOnUse">*/}
        {/*      <stop stopColor="var(--color-onverhard-pink)"></stop>*/}
        {/*      <stop offset="1" stopColor="var(--color-onverhard-neonpink)"></stop>*/}
        {/*    </linearGradient>*/}
        {/*  </defs>*/}
        {/*</svg>*/}
      {/*</div>*/}
    </div>
    <div className={styles.data}>
      <div className={styles.header}>
        <Title className={styles.title}
               size={TitleSize.XLarge}
               tag={TitleElement.H1}
               subTitle={subTitle?.title}
               direction={TitleDirection.Column}
        >
          {title}
        </Title>
      </div>
    </div>
  </div>
}

export default BioImage
