import type { FunctionComponent } from 'react'

import clsx from 'clsx'

import Caption from '../../../atoms/caption'
import Icon from '../../../atoms/icon'
import Paragraph, { ParagraphWeight } from '../../../atoms/paragraph'
import Title, { TitleSize } from '../../../atoms/title'
import Button from '../../../molecules/button'
import Image from '../../../molecules/image'
import ProgressBar, { ProgressBarSize } from '../../../molecules/progress-bar'
import Card from '../../card'
import Mediaplayer from '../../mediaplayer'
import StoryCard from '../../story-card'
import { LivestreamCardProps } from '../index'

import styles from './detailed.module.scss'

const LivestreamCardDetailed: FunctionComponent<LivestreamCardProps> = (
  {
    link,
    icon,
    title,
    subtitle,
    description,
    button,
    label,
    time,
    withHover = true,
    className,
    image,
    imageTitle,
    progress,
    livestream,
    channels,
    shareLinks,
    darkMode = true,
    innerRef,
    match
  }
) => {
  return <Card
    link={link}
    className={clsx(
      styles.livestreamCardDetailed,
      match && styles.withTeaser,
      className
    )}
    withHover={withHover}
    innerRef={innerRef}
  >
    <div className={styles.content}>
      <div className={styles.image}>
        {livestream?.componentProps
          ? <Mediaplayer {...livestream?.componentProps} />
          : image
            ? <Image src={image} />
            : <Title size={TitleSize.Small} className={styles.message}>{button?.title}</Title>
        }
        {imageTitle && <Title size={TitleSize.XLarge} className={styles.imageTitle}>{imageTitle}<span className={styles.greenDot}>.</span></Title>}
      </div>
      <div className={styles.textContentWrapper}>
        <div className={styles.textContent}>
          <Paragraph className={styles.time} desktopWeight={ParagraphWeight.Medium} darkMode={darkMode}>
            {label?.title && <Button className={styles.label}>{label.title}</Button>}
            {time?.text}
          </Paragraph>
          <Title className={styles.title} size={TitleSize.Medium} darkMode={darkMode}>
            {icon && <Icon className={styles.titleIcon} name={icon} />} {title}
          </Title>
          <Paragraph className={styles.subTitle} darkMode={darkMode}>{subtitle}</Paragraph>
          <ProgressBar size={ProgressBarSize.Large} progress={progress} />
          <div className={styles.description}>
            <Paragraph darkMode={darkMode}>
              {description}
            </Paragraph>
            <div className={styles.channels}>
              {channels?.map((channel, index) => <Button
                key={`${channel.title}-${index}`}
                className={styles.channel}
                {...channel}
                iconDarkMode={darkMode}>
                {channel.title}
              </Button>)}
            </div>
          </div>
          <hr className={styles.line}/>
          {match && <div className={clsx(
            styles.teaser,
            styles.teaserMedium
          )}>
            <Title className={styles.teaserTitle} size={TitleSize.XSmall} iconAfter={'chevron-right'} darkMode={darkMode}>Volg de livestream ook via het wedstrijdverslag</Title>
            <StoryCard desktopTitleSize={TitleSize.XSmall} {...match.componentProps} darkMode={darkMode} />
            <hr className={styles.line}/>
          </div>}
          <div className={styles.share}>
            <Caption darkMode={darkMode}>livestream delen</Caption>
            {shareLinks?.map((link, index) => <Button
              key={`${link.iconBefore}-${index}`}
              className={styles.social}
              {...link}
              href={link.iconBefore !== 'link' ? link.href : undefined}
              onClick={() => {
                link.href && link.iconBefore === 'link' && navigator?.clipboard?.writeText(link.href)
                return false
              }}
              iconDarkMode={darkMode}></Button>
            )}
          </div>
        </div>
        {match && <div className={clsx(
          styles.teaser,
          styles.teaserLarge
        )}>
          <Title className={styles.teaserTitle} size={TitleSize.XSmall} iconAfter={'chevron-right'} darkMode={darkMode}>Volg de livestream ook via het wedstrijdverslag</Title>
          <StoryCard desktopTitleSize={TitleSize.XSmall} {...match.componentProps} darkMode={darkMode} />
        </div>}
      </div>
    </div>
  </Card>
}

export default LivestreamCardDetailed
