import type { FunctionComponent } from 'react'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

import { Theme } from '@sporza/config'
import clsx from 'clsx'
import { Gallery } from 'react-photoswipe-gallery'
import { Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import Icon from '../../../atoms/icon'
import Title, { TitleElement, TitleSize } from '../../../atoms/title'
import { Avatar } from '../../../molecules/avatar'
import Button, { ButtonVariant } from '../../../molecules/button'
import DataList from '../../../molecules/data-list'
import Image, { ImageLayout } from '../../../molecules/image'
import { onBeforeOpen } from '../../mastermedia'
import styles from '../bio.module.scss'
import { BioProps, BioType } from '../'

const BioDefault: FunctionComponent<BioProps> = (
  {
    type,
    title,
    img,
    shirtNumber,
    subTitle,
    instagram,
    dataList,
    teamImg,
    theme,
    designSystemBaseUrl
  }
) => {
  return <div
    className={clsx(
      styles.bio,
      type === BioType.Team ? styles.team : undefined,
      theme === Theme.OlympischeSpelen2024 && styles.themeOs2024,
      theme === Theme.ParalympischeSpelen2024 && styles.themeOs2024,
    )}
  >
    <div className={styles.profile}>
      <Avatar image={img} fallbackImage={`${designSystemBaseUrl}/images/placeholders/${type === BioType.Player ? 'player' : 'team'}.svg`} />
      {shirtNumber && <div className={styles.shirtNumber}>#<Title size={TitleSize.Small}>{shirtNumber}</Title></div>}
      {instagram && <Button
        className={styles.social}
        variant={ButtonVariant.tertiary}
        iconBefore={'channels-instagram'}
        iconAfter={'chevron-right'}
        href={instagram}
      >
        instagram
      </Button>}
    </div>
    <div className={styles.data}>
      <div className={styles.header}>
        <Title className={styles.title} size={TitleSize.Large} tag={TitleElement.H1}>{title}</Title>
        {subTitle && <Title size={TitleSize.XSmall} isExternal={subTitle.isExternal} link={subTitle.url} className={styles.subTitle}>
          {subTitle.img && <Image className={styles.logo} priority={true} alt={`Logo van ${subTitle.title}`} layout={ImageLayout.Square} src={subTitle.img} />}
          {subTitle.title}
          {subTitle.url && subTitle.isExternal && <Icon className={styles.iconExternal} name={'external-link'} />}
          {subTitle.url && !subTitle.isExternal && <Icon className={styles.iconLink} name={'chevron-right'} />}
        </Title>}
      </div>
      <div className={styles.swiper}>
        <Swiper pagination={{
          clickable: true,
          dynamicBullets: true
        }}
                modules={[Navigation, Pagination]}>
          {dataList && <SwiperSlide>
            <DataList items={dataList} />
          </SwiperSlide>}
          {teamImg && <SwiperSlide>
            <div className={styles.teamImg}>
              <Image priority={true} src={teamImg} alt={'afbeelding van het team'} />
            </div>
          </SwiperSlide>}
        </Swiper>
      </div>
      <div className={styles.noSwiper}>
        {dataList && <DataList items={dataList} />}
      </div>
    </div>
    {teamImg && <div className={clsx(
      styles.teamImg,
      styles.noSwiper
    )}>
      <Gallery onBeforeOpen={onBeforeOpen}>
        <Image addToGallery={true} priority={true} src={teamImg}  />
      </Gallery>
    </div>}
  </div>
}

export default BioDefault
