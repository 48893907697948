import type { BannerProps } from '../banner'

import { Theme } from '@sporza/config'
import clsx from 'clsx'
import { forwardRef } from 'react'

import Image from '../../../molecules/image'

import styles from './image.module.scss'

const BannerImage = forwardRef<HTMLInputElement, BannerProps>((
  {
    image,
    link,
    className,
    click,
    theme,
  },
  ref
) => {
  const Tag = link
    ? 'a'
    : 'div'

  return <Tag
    className={clsx(
      styles.wrapper,
      theme === Theme.PlayPremierLeague && styles.themePlayPremierLeague,
      className
    )}
    onClick={() => click()}
    href={link ? link : undefined}
    target={link ? '_blank' : undefined}
    ref={ref}
  >
    <Image
      src={image}
      className={styles.image}
      noCrop={true}
      priority={true}
    />
  </Tag>
})

BannerImage.displayName = 'BannerImage'

export {
  BannerImage
}
