import type { FunctionComponent, ReactElement, RefObject } from 'react'
import type { SectionProps } from '../index'

import clsx from 'clsx'

import Hidden from '../../../atoms/hidden'
import Title, { TitleElement, TitleSize } from '../../../atoms/title'
import Button from '../../../molecules/button'
import Filter, { FilterSize } from '../../../molecules/filter'
import List, { ListAlignment } from '../../list'

import commonStyles from './common.module.scss'

type SectionCommonProps = Omit<SectionProps, 'items' | 'imageLayout'> & {
  headerActions?: ReactElement
  className?: string
  innerRef?: RefObject<HTMLElement>
  quickLinkTitle?: string
}

const SectionCommon: FunctionComponent<SectionCommonProps> = (
  {
    title,
    hiddenTitle,
    quickLinkTitle,
    itemsAmount,
    text,
    link,
    linkAction,
    linkText,
    className,
    headerActions,
    analyticsId,
    children,
    innerRef,
    filter,
    handleFilter,
    ariaLabelledBy
  }
) => {
  const id = !ariaLabelledBy ? `id_${analyticsId}` : undefined
  const titleElement =  title
    && <Title
      id={id}
      size={TitleSize.Large}
      link={link}
      tag={TitleElement.H2}
      className={commonStyles.componentTitle}
    >{hiddenTitle && <Hidden>{hiddenTitle}</Hidden>}{title}</Title>

  const headerActionElement = headerActions
    && <div className={commonStyles.actions} aria-hidden={true}>{headerActions}</div>

  return <section
    ref={innerRef}
    analyticsid={analyticsId}
    className={className}
    aria-labelledby={ariaLabelledBy ? ariaLabelledBy : id}
  >
    {
      titleElement && (headerActionElement || filter)
        ? <div className={commonStyles.header}>
          {titleElement}
          {filter && <Filter size={FilterSize.Small} onSelect={handleFilter} {...filter} />}
          {headerActionElement}
        </div>
        : titleElement || headerActionElement
    }
    {quickLinkTitle && <Title
      size={TitleSize.Large}
      className={clsx(
        commonStyles.quickLinkTitle,
        itemsAmount === 1 && commonStyles.quickLinkTitle1,
        itemsAmount === 2 && commonStyles.quickLinkTitle2,
        itemsAmount === 3 && commonStyles.quickLinkTitle3,
        itemsAmount === 4 && commonStyles.quickLinkTitle4,
        itemsAmount === 5 && commonStyles.quickLinkTitle5,
        itemsAmount === 6 && commonStyles.quickLinkTitle6
      )}>
      {quickLinkTitle}
    </Title>}
    {text && <div className={commonStyles.text}>{text}</div>}
    {children}
    {
      (link || linkAction) && linkText
      && <List
        align={ListAlignment.Center}
        className={commonStyles.actionBar}
      >
        <Button className={commonStyles.moreButton} href={link} onClick={() => !link && linkAction && linkAction()} iconAfter="chevron-right">{linkText}</Button>
      </List>
    }
  </section>
}

export default SectionCommon
