import type { FunctionComponent } from 'react'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

import { Theme } from '@sporza/config'

import { DataListProps } from '../../molecules/data-list'

import BioImage from './layout/image'
import BioDefault from './layout'

enum BioType {
  Player = 'player',
  Team = 'team',
  Competition = 'competition',
  Category = 'category'
}

enum BioLayout {
  Default = 'default',
  Image = 'Image',
}

interface BioProps {
  type: BioType,
  title: string
  img?: string
  backgroundImg?: string
  shirtNumber?: number
  teamImg?: string
  subTitle?: {
    title?: string
    url?: string
    img?: string
    isExternal?: boolean
  }
  theme?: string | Theme
  dataList?: DataListProps['items']
  instagram?: string,
  designSystemBaseUrl?: string
}

const Bio: FunctionComponent<BioProps> = (props) => {
  const bioLayout: { [key in BioLayout]: JSX.Element } = {
    [BioLayout.Default]: <BioDefault {...props} />,
    [BioLayout.Image]: <BioImage {...props} />
  }

  return props.type === BioType.Category ? bioLayout[BioLayout.Image] : bioLayout[BioLayout.Default]
}

export default Bio

export type {
  BioProps
}

export {
  BioType
}
