import type { ClassValue } from 'clsx'
import type { FunctionComponent, PropsWithChildren } from 'react'
import type { Icons } from '../../design-tokens/iconography/icons'

import clsx from 'clsx'

import Icon from '../../atoms/icon'
import Image, { ImageLayout } from '../image'
import { SportTypes } from '../score'

import styles from './teamname.module.scss'

enum TeamnameSize {
  Small = 'Small',
  Large = 'Large',
  XLarge = 'XLarge',
  XXLarge = 'XXLarge'
}

type LogoPosition = 'top' | 'left' | 'right'

const logoPositionStyles: {
  [Key in LogoPosition]: string
} = {
  'top': styles.logoTop,
  'left': styles.logoLeft,
  'right': styles.logoRight
}

interface TeamnameProps extends PropsWithChildren {
  team: {
    name?: string
    shortName?: string
    href?: string
    logoUrl?: string
    winner?: boolean
  }
  sport?: SportTypes | string
  size?: string | TeamnameSize
  showLogos?: boolean
  showGoals?: boolean
  showLinks?: boolean
  logoPosition?: LogoPosition
  apiHost?: string
  target?: string
  darkMode?: boolean
  fallbackImage?: string
  imageIcon?: Icons
  isAway?: boolean
  className?: ClassValue
  clamp?: number
}

const imageLayouts: { [Key in SportTypes as string ]: ImageLayout} = {
  soccer: ImageLayout.Square,
  tennis: ImageLayout.Widescreen,
  cycling: ImageLayout.Square,
  basketball: ImageLayout.Square,
  hockey: ImageLayout.Widescreen,
  handball: ImageLayout.Widescreen,
  volleyball: ImageLayout.Widescreen,
  formula1: ImageLayout.Square
}

const Teamname: FunctionComponent<TeamnameProps> = (
  {
    team,
    sport,
    size = TeamnameSize.Small,
    showLogos= true,
    logoPosition = 'left',
    showLinks,
    target,
    darkMode,
    fallbackImage,
    imageIcon,
    isAway,
    className,
    clamp
  }
) => {
  const {
    name,
    shortName,
    href,
    logoUrl,
    winner
  } = team || {}
  const Tag = href && showLinks ? 'a' : 'span'
  const overrideImageLayout = logoUrl?.includes('/flags/') ? ImageLayout.Widescreen : false
  const nameText = shortName && size === TeamnameSize.Small
    ? shortName
    : name

  return <span className={clsx(
    styles.teamname,
    isAway ? styles.away : styles.home,
    size === TeamnameSize.Large && styles.teamnameLarge,
    size === TeamnameSize.XLarge && styles.teamnameXLarge,
    size === TeamnameSize.XXLarge && styles.teamnameGroot,
    sport === SportTypes.Hockey && styles.hockey,
    sport === SportTypes.Handball && styles.handball,
    winner && styles.winner,
    logoPositionStyles[logoPosition],
    darkMode ? styles.darkMode : undefined,
    className
  )}>
    {showLogos && <span className={clsx(
      styles.logo,
      overrideImageLayout && styles.logoBoxShadow
    )}>
      <Image
        alt={''}
        src={logoUrl || ''}
        fallbackImage={fallbackImage}
        layout={overrideImageLayout || imageLayouts[sport as SportTypes]}
        noCrop={!!overrideImageLayout}
        profiles={[
          {
            name: 'native_mobile_200_200',
            view: '200w'
          }
        ]}
      >
        {
          imageIcon
          ? <Icon name={imageIcon} className={styles.imageIcon} />
          : false
        }
      </Image>
    </span>}
    {
      nameText
      && <Tag
        href={showLinks ? href : undefined}
        target={showLinks && target ? target : undefined}
        className={clsx(
          styles.name,
          clamp === 2 ? styles.clamp2 : undefined
        )}
      >
        {nameText}
      </Tag>
    }
  </span>
}

export default Teamname

export {
  TeamnameSize,
  imageLayouts
}

export type {
  TeamnameProps,
  LogoPosition
}
