enum BannerTheme {
  light = 'light',
  dark = 'dark',
}

enum BannerVariant {
  default = 'default',
  appPromo = 'appPromo'
}

enum BannerLayout {
  default = 'default',
  small = 'small',
  image = 'image'
}

enum BannerLogo {
  vrtmax = 'vrtmax',
  engie = 'engie',
  EKWielrennen2024 = 'ek-wielrennen-2024'
}

export {
  BannerTheme,
  BannerVariant,
  BannerLayout,
  BannerLogo
}
