import type { FunctionComponent, PropsWithChildren } from 'react'
import type { Icons } from '../../design-tokens/iconography/icons'

import clsx from 'clsx'

import Icon from '../../atoms/icon'

import styles from './pill.module.scss'

enum PillColor {
  Default = 'default',
  Mute = 'mute'
}

enum PillSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large'
}

interface PillProps extends PropsWithChildren {
  text?: string
  label?: string
  ariaLabel?: string
  href?: string
  iconBefore?: Icons
  iconAfter?: Icons
  className?: string
  color?: PillColor
  size?: PillSize
  onClick?: () => void
}

const Pill: FunctionComponent<PillProps> = (
  {
    text,
    label,
    ariaLabel,
    href,
    size = PillSize.Small,
    color = PillColor.Default,
    children,
    iconBefore,
    iconAfter,
    className,
    onClick
  }
) => {
  const TagName = href || onClick ? 'a' : 'span'

  return <TagName
    href={href}
    onClick={onClick}
    className={clsx(
      styles.pill,
      color === PillColor.Mute && styles.mute,
      size === PillSize.Medium && styles.medium,
      size === PillSize.Large && styles.large,
      className
    )}
    aria-label={ariaLabel}
  >
    {iconBefore && <i className={styles.iconBefore}><Icon name={iconBefore}/></i>}
    <span className={styles.inner}>{text || label || children}</span>
    {iconAfter && <i className={styles.iconAfter}><Icon name={iconAfter}/></i>}
  </TagName>
}

export default Pill

export {
  PillColor,
  PillSize
}

export type {
  PillProps
}
