import type { FunctionComponent } from 'react'
import type { ScoreboardSetsProps } from '../default'

import clsx from 'clsx'

import Score, { StatusTypes } from '../../../../molecules/score'

import styles from './simple.module.scss'

type ScoreboardSimpleSetsProps = ScoreboardSetsProps

const SimpleSetsBoard: FunctionComponent<ScoreboardSimpleSetsProps> = (
  {
    ariaLabel,
    status,
    label,
    sets,
    sport
  }
) => {
  return <div className={styles.simple} aria-label={ariaLabel}>
    {
      label
      && (status === StatusTypes.End || status === StatusTypes.Live)
      && <div className={clsx(styles.header, status === StatusTypes.Live ? styles.live : undefined)}>{label}</div>
    }
    <div className={styles.wrapper}>
      {
        Array.isArray(sets)
          ? <Score
            layout="simple"
            sets={sets}
            sport={sport}
            status={status}
          />
          : <span className={styles.label}>{label}</span>
      }
    </div>
  </div>
}

export default SimpleSetsBoard

export type {
  ScoreboardSimpleSetsProps
}
