import type { FunctionComponent } from 'react'
import type { BaseEventProps } from './base-event'

import clsx from 'clsx'

import Category from '../../../atoms/category'
import Icon from '../../../atoms/icon'
import Meta from '../../../atoms/meta'
import Title, { TitleElement, TitleSize } from '../../../atoms/title'
import { scrollToElement } from '../../../molecules/field-timeline/event-set'
import Pill from '../../../molecules/pill'
import { Quote, QuoteComponentProps } from '../../../molecules/quote'
import { TimelineMode } from '../config'

import styles from './events.module.scss'

interface QuoteEventProps extends BaseEventProps {
  quote: QuoteComponentProps
}

const QuoteEvent: FunctionComponent<QuoteEventProps> = (
  {
    id,
    timestamp,
    label,
    subLabel,
    icon,
    title,
    text,
    quote,
    isEditable,
    isBelgian,
    mode = TimelineMode.Default,
    link,
  }
) => {
  const isDefault = mode === TimelineMode.Default
  const isTeaser = mode === TimelineMode.Teaser

  return <div
    className={clsx(
      isDefault && styles.event,
      isTeaser && styles.eventTeaser,
      isTeaser && styles.isClickable,
      styles.quote,
      'sw-timeline-item'
    )}
    data-event-id={id}
    data-is-editable={isEditable ? true : undefined}
    onClick={(e) => isTeaser && link && scrollToElement(e, `${link}#timeline-${timestamp}`)}
  >
    <span className={styles.eventAnchor} id={`timeline-${timestamp}`}></span>

    {
      isDefault
        ?
        <div className={styles.labelContainer}>
          {label && <Pill {...label} />}
        </div>
        : null
    }

    <div className={styles.eventContent}>
      {
        icon || title || subLabel && isDefault
          ? <div className={styles.iconTitleContainer}>
            {
              isBelgian
                ? <Icon ariaLabel="Belg" name="os-2024-belg" />
                : null
            }
            {
              subLabel
              && isDefault
              && <Category className={styles.subLabel}>{subLabel.label}</Category>
            }
            {icon && <Icon {...icon} ariaHidden className={styles.icon}/>}
            {title && <Title size={TitleSize.Small} tag={TitleElement.H3}>{title}</Title>}
          </div>
          : null
      }
      {text && <div dangerouslySetInnerHTML={{ __html: text }} />}
      {quote && <Quote {...quote.componentProps} />}
    </div>

    {
      isTeaser
        ?
        <div className={styles.eventFooter}>
          {label?.label && <Meta iconBefore={label.iconBefore}>{label.label}</Meta>}
          {subLabel && <Pill {...subLabel} iconBefore={undefined} />}
        </div>
        : null
    }
  </div>
}

export default QuoteEvent

export type {
  QuoteEventProps,
}
