import type { FunctionComponent } from 'react'

import { Theme } from '@sporza/config'
import { ComponentType, mapToComponent } from '@sporza/services/component-mapper'
import clsx from 'clsx'

import Hidden from '../../../atoms/hidden'
import Title, { TitleElement, TitleSize } from '../../../atoms/title'
import { Advertisement, AdvertisementGroup } from '../../../molecules/advertisement'
import Cell from '../../cell'
import Container from '../../container'
import Grid from '../../grid'
import { NavigationSimple } from '../../navigation-simple'
import { PageType } from '../page'

import styles from './default.module.scss'

interface DefaultPageProps {
  title: string,
  header: any[],
  main: any[],
  mainBody: any[],
  aside: any[],
  apiBaseUrl?: string,
  designSystemBaseUrl?: string,
  bffBaseUrl?: string,
  classNames?: string
  pageType?: string
  theme?: string
  seoTitle?: string
  hideAds?: boolean
  children?: any
  subNav?: any
  darkMode?: boolean
}

const DefaultPage: FunctionComponent<DefaultPageProps> = (
  {
    title,
    header: headerItems = [],
    main: mainItems = [],
    mainBody: mainBodyItems = [],
    aside: asideItems = [],
    apiBaseUrl,
    designSystemBaseUrl,
    bffBaseUrl,
    classNames,
    pageType,
    theme,
    hideAds,
    seoTitle,
    subNav,
    darkMode,
    children
  }
) => {
  const headerChildren = !!headerItems?.length && <Grid>
    <Cell size={4}>
      {headerItems.map((item: any) => mapToComponent({
        ...item,
        darkMode
      }, { apiBaseUrl, designSystemBaseUrl, bffBaseUrl }))}
    </Cell>
  </Grid>

  const showTitle = !headerItems?.some((item: any) => item.componentType === ComponentType.Bio)

  const accessibilityTitle = (() => {
    switch (true) {
      case pageType === PageType.Home:
        return 'Hoofdpunten'
      case pageType === PageType.Category || pageType === PageType.Search:
        return seoTitle
      default:
        return
    }
  })()

  const accessibilitySubTitle = (() => {
    switch (true) {
      case pageType === PageType.Home:
        return 'belangrijkste hoofdpunten'
      case pageType === PageType.Category:
        return `belangrijkste ${title} nieuws`
      default:
        return
    }
  })()

  mainItems = mainItems.map(item => {
    if (item.componentType === 'text') {
      return {
        ...item,
        componentProps: {
          ...item.componentProps,
          darkMode,
          className: `${item.componentProps.className || ''} ${styles.paragraph}`.trim()
        }
      }
    }

    return item
  })

  const titleContent = accessibilityTitle && accessibilitySubTitle
    ? title
      ? <Title size={TitleSize.Large}>{title}<Hidden tag={TitleElement.H2}>{accessibilitySubTitle}</Hidden></Title>
      : <Hidden tag={TitleElement.H2}>{accessibilitySubTitle}</Hidden>
    : <Title size={TitleSize.Large} tag={accessibilityTitle ? TitleElement.H2 : TitleElement.H1}>{title}</Title>

  return <div className={clsx(
    theme === Theme.Onverhard && styles.onverhard,
    darkMode && styles.darkMode
  )}>
    {showTitle && accessibilityTitle && <Hidden tag={TitleElement.H1}>{accessibilityTitle}</Hidden>}
    {
      headerChildren
        ? <Container
          tag="header"
          id={'content'}
          collapsed
          className={clsx(
            classNames
          )}
        >
          {headerChildren}
          {subNav && <NavigationSimple tag={'nav'} ariaLabel={'subnavigatie'} items={subNav}/>}
        </Container>
        : false
    }
    <Container tag="div" className={clsx(
      classNames
      )}>
      {children === undefined ? <Grid collapse>
        <Cell size={asideItems?.length ? 3 : 4}>
          <main id={!headerChildren ? 'content' : undefined} className={theme === Theme.Eddies ? styles.main : undefined}>
            {showTitle && titleContent}
            {
              [
                ...mainItems,
                ...mainBodyItems
              ].map((item: any) => mapToComponent({
                ...item,
                darkMode
              }, { apiBaseUrl, designSystemBaseUrl, bffBaseUrl }))
            }
          </main>
        </Cell>
        {!!asideItems?.length && <Cell size={1}>
          <aside>
            {asideItems.map((item: any) => mapToComponent({
              ...item,
              darkMode
            }, { apiBaseUrl, designSystemBaseUrl, bffBaseUrl }))}
          </aside>
        </Cell>}
      </Grid> : children}

      {!hideAds && <AdvertisementGroup>
        <Advertisement adType="cba"/>
        <Advertisement adType="cba"/>
        <Advertisement adType="cba"/>
        <Advertisement adType="cba"/>
      </AdvertisementGroup>}
    </Container>
  </div>
}

export {
  DefaultPage
}

export type { DefaultPageProps }

