import type { FunctionComponent, RefObject } from 'react'
import type { BannerClickArguments } from '@sporza/tracking/events'

import { Theme } from '@sporza/config'
import { useBannerImpression } from '@sporza/tracking'
import { bannerClickEvent } from '@sporza/tracking/events'
import { getPathName, getPermalinkId } from '@sporza/utils/urls'
import { useRef } from 'react'

import {
  BannerDefault,
  BannerImage,
  BannerLayout,
  BannerLogo,
  BannerSmall,
  BannerTheme
} from './index'

const layoutComponents = {
  [BannerLayout.default]: BannerDefault,
  [BannerLayout.small]: BannerSmall,
  [BannerLayout.image]: BannerImage,
}

interface BannerProps {
  layout?: BannerLayout
  image?: string
  title?: string
  subTitle?: string
  text?: string
  buttonLabel?: string
  theme?: string | BannerTheme | Theme
  logo?: BannerLogo
  variant?: string
  link?: string
  className?: string,
  designSystemBaseUrl?: string
  ref?: RefObject<Element>
  click?: any,
  useOutset?: boolean
}

const getBannerAdvertiser = (theme: Theme) => {
  switch (theme) {
    case Theme.PlayPremierLeague:
    case Theme.Play6:
      return 'Telenet Play6'
    case Theme.Play:
      return 'Telenet Play'
    default:
      return
  }
}

const getBannerComponentName = (layout: string) => {
  switch (layout) {
    case BannerLayout.default:
      return 'Banner'
    case BannerLayout.image:
      return 'BannerImage'
    case BannerLayout.small:
      return 'BannerSmall'
  }
}

const Banner: FunctionComponent<BannerProps> = (
  props
) => {
  const {
    title,
    link,
    theme,
    layout = BannerLayout.default,
    designSystemBaseUrl
  } = props

  const bannerRef = useRef(null)
  const bannerContext = {
    bannerAdvertiser: getBannerAdvertiser(theme as Theme),
    bannerTitle: title ?? 'no banner title provided',
    componentName: getBannerComponentName(layout),
    targetPageUrl: link,
    targetPageName: getPathName(link),
    targetPageId: getPermalinkId(link)
  }

  const trackBannerClick = (bannerContext: any) => {
    bannerClickEvent(bannerContext as BannerClickArguments)

    return true
  }

  useBannerImpression(
    {
      ref: bannerRef,
      ...bannerContext
    }
  )

  const Layout = layoutComponents[layout as keyof typeof layoutComponents]

  return <Layout
    {...props}
    designSystemBaseUrl={designSystemBaseUrl}
    ref={bannerRef}
    click={() => trackBannerClick(bannerContext)}
  />
}

export default Banner

export type {
  BannerProps
}
