import type { FunctionComponent } from 'react'
import type { ScoreboardScoreProps } from '../default'

import { designSystemBaseUrl } from '@sporza/config'
import clsx from 'clsx'

import Score, { ScoreLayoutTypes, StatusTypes } from '../../../../molecules/score'
import Teamname, { TeamnameSize } from '../../../../molecules/teamname'

import styles from './simple.module.scss'

type ScoreboardSimpleScoreProps = ScoreboardScoreProps & {
  showTeams?: boolean
}

const SimpleScoreBoard: FunctionComponent<ScoreboardSimpleScoreProps> = (
  {
    ariaLabel,
    sport,
    status,
    label,
    home,
    away,
    score,
    showTeams,
    layout
  }
) => {
  return <div className={styles.simple} aria-label={ariaLabel}>
    {
      label
      && (status === StatusTypes.End || status === StatusTypes.Live)
      && <div className={clsx(styles.header, status === StatusTypes.Live ? styles.live : undefined)}>{label}</div>
    }
    <div className={styles.wrapper}>
      {
        !score
          ? <span className={styles.label}>{label}</span>
          : <>
            <Teamname
              className={clsx(
                styles.teamLeft,
                showTeams && styles.teamVisible
              )}
              size={TeamnameSize.Small}
              fallbackImage={`${designSystemBaseUrl}/images/placeholders/team.svg`}
              sport={sport}
              team={home}
              showLogos={false}
              showLinks={false}
              logoPosition={layout === 'compact' ? 'top' : 'left'}
            />

            <Score layout={ScoreLayoutTypes.Simple}
                   score={score}
                   sport={sport}
                   status={status}
            />

            <Teamname
              className={clsx(
                styles.teamRight,
                showTeams && styles.teamVisible
              )}
              size={TeamnameSize.Small}
              fallbackImage={`${designSystemBaseUrl}/images/placeholders/team.svg`}
              sport={sport}
              team={away}
              showLogos={false}
              showLinks={false}
              logoPosition={layout === 'compact' ? 'top' : 'right'}
              isAway={true}
            />
          </>
      }
    </div>
  </div>
}

export default SimpleScoreBoard

export type {
  ScoreboardSimpleScoreProps,
}
