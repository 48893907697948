enum Theme {
  OlympischeSpelen2024 =      'generic-os2024',
  ParalympischeSpelen2024 =   'generic-paralympische-spelen-2024',
  Default =                   'generic-default',
  Vrtmax =                    'generic-vrtmax',
  Play =                      'generic-play',
  Play6 =                     'generic-play6',
  PlayMadness =               'generic-play-madness',
  PlayPremierLeague =         'generic-play-premierleague',
  Eddies =                    'generic-eddies',
  Lidl =                      'generic-lidl',
  PlayFormula1 =              'generic-play-formula1',
  Onverhard =                 'generic-onverhard',
  SportVlaanderen =           'sport-vlaanderen',
  CyclingEkWielrennen2024 =   'cycling-ekwielrennen-2024'
}

type ThemeConfig = {
  theme?: Theme,
  type?: string,
  [key: string]: any
}

const OlympischeSpelenColors: Record<string, string> = {
  '#009CE0': '#FBA9CB',
  '#022366': '#009CE0',
  '#570047': '#FF5757',
  '#003853': '#17B7B0',
  '#331362': '#7D84C2'
}

const colorKeys = Object.keys(OlympischeSpelenColors)

const getThemeColorFromValue = (value?: string) => {
  const hash = value?.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0) ?? 0
  const colorIndex = hash % colorKeys.length

  return {
    '--avatar-border-color': colorKeys[colorIndex],
    '--avatar-border-color-darker': OlympischeSpelenColors[colorKeys[colorIndex]]
  }
}

export type {
  ThemeConfig
}

export {
  Theme,
  OlympischeSpelenColors,
  getThemeColorFromValue
}
