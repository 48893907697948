import type { FunctionComponent, ReactNode } from 'react'
import type { IconProps } from '../../atoms/icon'
import type { CardProps } from '../card'

import { VRTBrand } from '@sporza/config'
import { useListClick, useListImpression } from '@sporza/tracking'
import clsx from 'clsx'
import { useRef } from 'react'

import Caption from '../../atoms/caption'
import Category from '../../atoms/category'
import Icon from '../../atoms/icon'
import Logo from '../../atoms/logo'
import { mapBrandToLogo } from '../../atoms/logo/svgs'
import Title, { TitleSize } from '../../atoms/title'
import Button from '../../molecules/button'
import Image, { ImageFocalPoint, ImageLayout } from '../../molecules/image'
import { Notification, NotificationType } from '../../molecules/notification'
import Pill from '../../molecules/pill'
import Tag, { TagProps } from '../../molecules/tag'
import Card from '../card'
import List from '../list'
import Scoreboard, { ScoreboardComponentProps, ScoreboardLayoutTypes } from '../scoreboard'

import styles from './story-card.module.scss'

enum StoryCardType {
  Article = 'Article',
  Podcast = 'Podcast',
  Teaser = 'Teaser'
}
enum StoryCardLayout {
  Horizontal = 'Horizontal',
  Vertical = 'Vertical'
}

interface StoryCardComponentProps {
  componentType: string
  componentProps: StoryCardProps
}

interface StoryCardProps extends CardProps {
  type?: StoryCardType | string
  image?: string
  imageTitle?: string
  imageAlt?: string
  imageLayout?: string | ImageLayout
  imageFocalPoint?: string | ImageFocalPoint
  label?: string
  title?: string
  text?: string
  tags?: TagProps[]
  layout?: string | StoryCardLayout
  desktopLayout?: string | StoryCardLayout
  titleSize?: string | TitleSize
  desktopTitleSize?: string | TitleSize
  video?: boolean
  brand?: VRTBrand | string,
  imageChildren?: ReactNode,
  match?: ScoreboardComponentProps,
  livestreamLabel?: string,
  videoLabel?: string
  videoAriaLabel?: string
  audioLabel?: string
  audioAriaLabel?: string
  iconAfter?: IconProps
  timestamp?: number
  priority?: boolean
  columns?: number
  labelIcon?: string
  unpublished?: boolean
  source?: string
  useOutset?: boolean
  tabIndex?: number
}

const StoryCard: FunctionComponent<StoryCardProps> = (
  {
    type = StoryCardType.Article,
    link,
    target,
    image,
    imageTitle,
    imageAlt,
    imageLayout,
    imageFocalPoint,
    label,
    title,
    text,
    tags = [],
    brand,
    withHover = true,
    layout = StoryCardLayout.Horizontal,
    desktopLayout = StoryCardLayout.Horizontal,
    titleSize = TitleSize.XSmall,
    desktopTitleSize = TitleSize.Small,
    className,
    darkMode,
    imageChildren,
    match,
    livestreamLabel,
    videoLabel,
    videoAriaLabel,
    audioLabel,
    audioAriaLabel,
    ebaData = {},
    iconAfter,
    priority = false,
    columns,
    labelIcon,
    unpublished,
    source,
    useOutset,
    tabIndex
}
) => {
  const ref = useRef(null)
  const hasTags = !!tags?.length
  const brandLogo = brand && mapBrandToLogo(brand)

  useListClick({
    ref,
    ...ebaData
  })

  useListImpression(
    {
      ref,
      ...ebaData
    }
  )

  const labelItem = () => {
    switch (type) {
      case StoryCardType.Podcast:
        return <Caption>{label}</Caption>
      default:
        return <Category
          className={clsx(
            label?.toUpperCase() === 'OPVALLEND' && styles.reversedCategoryColors
          )}>
          {labelIcon && <Icon name={labelIcon} withBackground />}
          {label}
        </Category>
    }
  }

  const mapToTag = (tags:TagProps[]) => {
    return <List className={styles.storyCardContentTags} darkMode={darkMode}>
      {tags?.map((tag, index) =>
        <Tag key={`${index}-${tag.text}`} darkMode={darkMode} {...tag} />
      )}
    </List>
  }

  const matchScoreboard = match
    ? <Scoreboard
      className={styles.scoreboard}
      layout={ScoreboardLayoutTypes.Simple}
      {...match.componentProps}
    />
    : undefined

  const livestreamButton = livestreamLabel === 'livestream'
    ? <Pill className={styles.liveButton} iconBefore='play-video-solid'>{livestreamLabel}</Pill>
    : undefined

  const livestreamTag = livestreamLabel && livestreamLabel !== 'livestream'
    ? <Pill className={styles.liveTag} iconBefore='play-video-solid'>{livestreamLabel}</Pill>
    : undefined

  const videoButton = videoLabel
    ? <Pill className={styles.videoButton} ariaLabel={videoAriaLabel} iconBefore='play-video-solid'>{videoLabel}</Pill>
    : undefined

  const audioButton = audioLabel
    ? <Pill className={styles.audioButton} ariaLabel={audioAriaLabel} iconBefore='audio'>{audioLabel}</Pill>
    : undefined

  const unpublishedMessage = unpublished && <Notification
    type={NotificationType.Warning}
    darkMode={true}
    text={'Dit artikel is nog niet gepubliceerd.'}
  />

  const externalLinkButton = link && type === StoryCardType.Teaser &&
    <Button className={styles.storyCardButton} iconAfter="external-link" size="small">
      {source === 'podcast' ? 'luister op ' : 'kijk op '}
      {brand === VRTBrand.VRTMAX && 'VRT Max' || brand === VRTBrand.VRTNWS && 'VRT NWS'}
    </Button>


  return <Card
    tabIndex={tabIndex}
    link={link}
    target={target}
    className={clsx(
      styles.storyCard,
      type === StoryCardType.Article && styles.storyCardTypeArticle,
      type === StoryCardType.Podcast && styles.storyCardTypePodcast,
      type === StoryCardType.Teaser && styles.storyCardTypeTeaser,
      type === StoryCardType.Teaser && imageLayout === ImageLayout.Square && styles.storyCardTypeTeaserSquare,
      layout === StoryCardLayout.Horizontal && styles.storyCardLayoutHorizontal,
      layout === StoryCardLayout.Vertical && styles.storyCardLayoutVertical,
      desktopLayout === StoryCardLayout.Horizontal && styles.storyCardDesktopLayoutHorizontal,
      desktopLayout === StoryCardLayout.Vertical && styles.storyCardDesktopLayoutVertical,
      brand === VRTBrand.VRTNWS && styles.vrtNws,
      darkMode && styles.dark,
      link && withHover && styles.withHover,
      brandLogo && styles.hasBrand,
      useOutset && styles.outset,
      className
    )}
    brand={brand}
    withHover={withHover}
    darkMode={darkMode}
    innerRef={ref}
  >
    {image
      && <div className={styles.storyCardImageContainer}>
        <Image
          src={image}
          title={imageTitle}
          alt={imageAlt}
          columns={columns}
          focalPoint={imageFocalPoint}
          layout={imageLayout}
          priority={priority}
          className={styles.storyCardImage}
        />
        <div className={styles.storyCardMedia}>
          {imageChildren}
          {livestreamButton}
          {videoButton || audioButton}
          {matchScoreboard}
          {unpublishedMessage}
        </div>
      </div>
    }
    <div className={clsx(
      styles.storyCardContent,
      iconAfter && styles.hasIconAfter
    )}>
      {label && labelItem()}
      {title && <Title size={titleSize} desktopSize={desktopTitleSize} >
        {type === StoryCardType.Podcast && <Icon name={'audio'} withBackground  className={styles.titleIcon}/>}
        {title}
      </Title>}
      {text && <div className={styles.storyCardContentText}>{text}</div>}
      {livestreamTag}
      {hasTags && mapToTag(tags)}
      {externalLinkButton}
      {brandLogo
        && <Logo
          logo={brandLogo}
          monoTone={type !== StoryCardType.Teaser}
          darkMode={darkMode}
          className={styles.storyCardContentBrand}
        />}
    </div>
    {
      iconAfter
        && <div className={styles.iconAfter}><Icon {...iconAfter} /></div>
    }
  </Card>
}

export default StoryCard

export {
  StoryCardLayout,
  StoryCardType
}

export type {
  StoryCardProps,
  StoryCardComponentProps
}
