import type { CSSProperties, FunctionComponent } from 'react'
import type { BannerClickArguments } from '@sporza/tracking/events'

import { Theme, ThemeConfig } from '@sporza/config'
import { useBannerImpression } from '@sporza/tracking'
import { bannerClickEvent } from '@sporza/tracking/events'
import { getPathName } from '@sporza/utils/urls'
import { useRef } from 'react'

import { Image } from '../../../molecules/image'
import { generateDefaultSrc } from '../../../molecules/image/helpers'

import styles from './ad-banner.module.scss'

interface AdBannerThemeConfig extends ThemeConfig {
  backgroundImageUrl?: string
  leftImageUrl?: string
  rightImageUrl?: string
  logoUrl?: string
  accentColor?: string
  textColor?: string,
  link?: string
  bannerContext?: any
}

interface AdBannerProps {
  themeConfig?: AdBannerThemeConfig
  designSystemBaseUrl?: string
}

const AdBanner: FunctionComponent<AdBannerProps> = (
  {
    themeConfig
  }
) => {
  const {
    backgroundImageUrl,
    leftImageUrl,
    centerImageUrl,
    rightImageUrl,
    link,
    bannerContext
  } = themeConfig || {}

  const { title, bannerId, bannerName, bannerAdvertiser } = bannerContext || {}

  const bannerRef = useRef(null)

  const context = {
    bannerTitle: title,
    componentName: bannerName,
    componentId: bannerId,
    targetPageUrl: link,
    targetPageName: getPathName(link),
    targetPageId: link,
    bannerAdvertiser: bannerAdvertiser
  }

  const trackBannerClick = (bannerContext: any) => {
    bannerClickEvent(bannerContext as BannerClickArguments)

    return true
  }

  useBannerImpression(
    {
      ref: bannerRef,
      ...context
    }
  )

  return <a
    className={styles.adBanner}
    style={backgroundImageUrl !== undefined ? { '--play-banner-background': `url("${generateDefaultSrc(`${backgroundImageUrl}`, 'width960hx')}") center` } as CSSProperties : undefined}
    href={link}
    target="_blank"
    rel="noreferrer"
    ref={bannerRef}
    title={context.bannerTitle}
    onClick={() => trackBannerClick(context)}
  >
    <div className={styles.left}>
      {leftImageUrl && <Image focalPoint={'Ml'} noCrop src={leftImageUrl} />}
    </div>
    <div className={styles.center}>
      {centerImageUrl && <Image noCrop src={centerImageUrl} />}
    </div>
    <div className={styles.right}>
      {rightImageUrl && <Image focalPoint={'Mr'} noCrop src={rightImageUrl} />}
    </div>
  </a>
}

export type {
  AdBannerProps
}

export {
  AdBanner
}
